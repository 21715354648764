@import './fonts.css';
@import './tailwind.css';
/**
 * Remember to import this in preview.js for it to work in storybook
 */
@import './buttons.css';

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  /* Hide scrollbar on firefox */
  scrollbar-width: none;
  /** Safari scroll overflow fix */
  overflow: hidden;
  font-family: Prohibition;
  font-weight: normal;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

/* Hide scrollbar */
::-webkit-scrollbar,
::-webkit-scrollbar-track,
::-webkit-scrollbar-thumb,
::-webkit-scrollbar-thumb:hover {
  display: none;
}

@keyframes flicker {
  20% {
    opacity: 1;
  }

  22% {
    opacity: 0;
  }

  24% {
    opacity: 1;
  }

  26% {
    opacity: 0;
  }

  28% {
    opacity: 1;
  }
}
