@import './tailwind.css';
/** All buttons */
.btn {
	@apply font-bold py-2 px-4 rounded cursor-pointer w-fit;
}
/** Primary button */
.btn-primary {
	@apply bg-blue-500 text-white;
}
.btn-primary:hover {
	@apply bg-blue-700;
}
/** Primary outline button */
.btn-primary-outlined {
	@apply bg-transparent text-blue-700 font-semibold py-2 px-4 border border-blue-500 rounded;
	border: 1px solid;
}
.btn-primary-outlined:hover {
	@apply bg-blue-500 border-transparent text-white;
}
/** Secondary button */
.btn-secondary {
	@apply bg-teal-500 text-white;
}
.btn-secondary:hover {
	@apply bg-teal-700;
}
/** Elevated button */
.btn-elevated {
	@apply bg-white text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow;
}
.btn-elevated:hover {
	@apply bg-gray-100;
}
