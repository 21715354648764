/* Import fonts */
@import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap');

@font-face {
	font-family: Prohibition;
	font-weight: normal;
	src: url('~/public/static/font/Prohibition-Regular.woff2') format('woff2');
}

@font-face {
	font-family: Rift;
	font-weight: normal;
	src: url('~/public/static/font/Rift-Regular.woff2') format('woff2');
}

@font-face {
	font-family: Rift;
	font-weight: 700, medium;
	src: url('~/public/static/font/Rift-Medium.woff2') format('woff2');
}

@font-face {
	font-family: Rift;
	font-weight: 800, bold;
	src: url('~/public/static/font/Rift-Bold.woff2') format('woff2');
}
